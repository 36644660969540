.connectVideo {
	/* max-width: 434px;
	max-height: 574px;	 */
	width: 100%;
	height: 100%;
	margin: 0px !important;
	padding: 0px !important;
	object-fit: cover;
	outline: none !important;
	border: "none";
	-webkit-box-sizing: unset;
	-moz-box-sizing: unset;
	box-sizing: unset;
	-webkit-mask-image: -webkit-radial-gradient(white, black);
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	overflow: hidden !important;
	-webkit-transform: translateZ(0);
	border-radius: 24px;
}